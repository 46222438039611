/** @jsx jsx */

import { jsx } from "@emotion/react"
import { Page } from "@src/components"
import Layout from "@src/components/layout"
import { graphql } from "gatsby"

export default ({ data }) => {
  const { title, content } = data.datoCmsMagazineItem
  return <Page {...{ title, content, locale: "en" }} />
}

export const query = graphql`
  query MagazineItemEnQuery($id: String!) {
    datoCmsMagazineItem(locale: { eq: "en" }, id: { eq: $id }) {
      title
      content {
        ... on DatoCmsBanner {
          ...BannerFragment
        }
        ... on DatoCmsIntro {
          ...IntroFragment
        }
        ... on DatoCmsDoubleImage {
          ...DoubleImageFragment
        }
        ... on DatoCmsTextBlock {
          ...TextBlockFragment
        }
        ... on DatoCmsTextImageBlock {
          ...TextImageBlockFragment
        }
        ... on DatoCmsTextImageBlockLeft {
          ...TextImageBlockLeftFragment
        }
        ... on DatoCmsQuote {
          ...QuoteFragment
        }
        ... on DatoCmsEventsList {
          ...EventsListFragment
        }
        ... on DatoCmsMagazine {
          ...MagazineFragment
        }
        ... on DatoCmsSocial {
          ...SocialFragment
        }
      }
    }
  }
`
